<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <div class="col-12 d-flex justify-content-between">
            <b-card-title class="h1 d-block">{{$t('tiktok.mentions')}}</b-card-title>
            <div class="d-block" v-if="exportable.length > 0">
              <b-button
                variant="success"
                @click="exportSelected()"
              >
                {{$t('campaigns.export')}}
              </b-button>
            </div>
          </div>

          <component
            :is="vue_good_table"
            :columns="columns"
            :rows="rows"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true,
              selectAllByGroup: true,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            class="vgt-table-profile-tiktok"
            @on-selected-rows-change="selectionChanged"
          >
            <template slot="table-column" slot-scope="props">
              <div v-if="props.column.field === 'username'" class="cursor-pointer">
                {{ $t(props.column.label) }}
                <b-icon icon="chevron-bar-expand" class="float-right"/>
              </div>
              <div v-if="props.column.field === 'posts_ids'" class="d-block text-center cursor-pointer">
                {{ $t(props.column.label) }}
                <b-icon icon="chevron-bar-expand" class="float-right"/>
              </div>
              <div v-if="props.column.field === 'mentioned_posts_er_mark'" class="d-block text-center cursor-pointer">
                {{ $t(props.column.label) }}
                <b-icon icon="chevron-bar-expand" class="float-right"/>
              </div>
                <div v-if="props.column.field === 'mentioned_posts_views'" class="d-block text-center cursor-pointer">
                {{ $t(props.column.label) }}
                <b-icon icon="chevron-bar-expand" class="float-right"/>
              </div>
                <div v-if="props.column.field === 'last_mentioned_post'" class="d-block text-center cursor-pointer">
                {{ $t(props.column.label) }}
                <b-icon icon="chevron-bar-expand" class="float-right"/>
              </div>

            </template>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'username'" class="text-nowrap row-selected-table">
                <div class="d-flex align-items-center">
                  <b-avatar
                    :src="props.row.avatar_url"
                    size="md"
                    variant="light-primary"
                  />
                  <div class=" ml-2">  
                    <span class="d-block">
                      <strong class="col-12 p-0">{{props.row.title}}</strong> 
                      <b-icon v-if="props.row.is_verified" icon="check-circle-fill" aria-hidden="true" class="iconCheck pl-1 pr-2"/>
                      <b-badge v-if="props.row.is_brand" variant="light-secondary">{{$t('tiktok.brand')}}</b-badge>
                    </span>
                    <span class="text-muted">@{{props.row.username}} - {{getFormat(props.row.followers_count)}}</span>
                  </div>
                </div>
              </span>
              <span
                v-else-if="props.column.field === 'posts_ids'"
                class="text-nowrap d-block text-center"
              >
                <span class="text-nowrap">{{props.row.posts_ids}} {{$t('tiktok.mentions')}}</span>
              </span>
              <span
                v-else-if="props.column.field === 'mentioned_posts_er_mark'"
                class="text-nowrap d-block text-center p-1"
              >
                <b-badge :variant="statusVariant(props.row.mentioned_posts_er_mark)">{{$t(`tiktok.${props.row.mentioned_posts_er_mark}`)}}</b-badge>
                <span class="d-block">{{decimalFixed(props.row.mentioned_posts_er, 2)}} %</span>
              </span>
              <span
                v-else-if="props.column.field === 'mentioned_posts_views'"
                class="text-nowrap d-block text-center"
              >
                <span class="h5">{{getFormat(props.row.mentioned_posts_views)}}</span>
              </span>
              <span
                v-else-if="props.column.field === 'published_contents'"
                class="text-nowrap text-center d-block"
              >
              </span>
              <span
                v-else-if="props.column.field === 'last_mentioned_post'"
                class="text-nowrap d-block text-center"
              >
                <span>{{getDateUnixToTime(props.row.last_mentioned_post)}}</span>
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> {{$t('campaigns.showing_to')}} </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3', '5', '10']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    {{$t('campaigns.of')}} {{ props.total }} {{$t('campaigns.entries')}}
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </component>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  VBTooltip,
  VBToggle,
  BBadge,
  BFormSelect,
  BPagination,
  BCardTitle,
  BButton,
} from "bootstrap-vue";
import service from '@/services/profile';
import { getDatev2 } from '@/libs/utils/times';
import { decimalFixed, getFormat } from '@/libs/utils/formats';
import { statusVariant } from '@/libs/utils/series_graphs';

export default {
  name: 'mentionsTables',
  props: {
    username: {
      type: String,
      required: true
    }
  },
  components: {
    BBadge,
    BFormSelect,
    BPagination,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardTitle,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      decimalFixed,
      getFormat,
      statusVariant,
      exportable: [],
      creator: {},
      show_side: false,
      local: window.location.origin,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "search.account",
          field: "username",
        },
        {
          label: "tiktok.mentions",
          field: "posts_ids",
          type: "number"
        },
        {
          label: "campaigns.erTitle",
          field: "mentioned_posts_er_mark",
        },
        {
          label: "youtube.visualizations",
          field: "mentioned_posts_views",
          type: "number"
        },
        {
          label: "tiktok.mentions_last",
          field: "last_mentioned_post",
          type: "number"
        },
      ],
      rows: [],
      info: [],
      vue_good_table: null,
    };
  },
  methods: {
    getData() {
      service.getMentions(this.username).then((response) => {
        if (!Array.isArray(response)) {
          const keys = Object.keys(response.mentions.performance['all'].data)
          const new_array = Object.values(response.mentions.performance['all'].data).map((item, index) => {
            item['username'] = response.users[keys[index]].username;
            item['title'] = response.users[keys[index]].title;
            item['avatar_url'] = response.users[keys[index]].avatar_url;
            item['followers_count'] = response.users[keys[index]].followers_count;
            item['is_brand'] = response.users[keys[index]].is_brand;
            item['is_verified'] = response.users[keys[index]].is_verified;
            item.posts_ids = item.posts_ids.length;
            return item
          })
          this.rows = new_array;
        }
      })
    },
    getDateUnixToTime(unix_timestamp) {
      return getDatev2(new Date(unix_timestamp * 1000))
    },
    selectionChanged(data) {
      this.exportable = data.selectedRows
    },
    exportSelected() {
      const rows = [['Influencer', 'Menciones', 'Engagement', 'Visualizaciones', 'Menciones más recientes']];

      this.exportable.forEach(data => {
        rows.push([
          data.username,
          data.mentioned_posts_count,
          data.mentioned_posts_er,
          data.mentioned_posts_views,
          this.getDateUnixToTime(data.last_mentioned_post)
        ])
      })
      const csv = rows.map(e => e.join(",")).join("\n")
      const downloadLink = document.createElement("a");
      const blob = new Blob(["\ufeff", csv]);
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = "Menciones.csv";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  },
  created() {
    this.getData();
  },
  async mounted() {
    this.vue_good_table = (await import('vue-good-table')).VueGoodTable
  }
};
</script>
<style lang="scss">
.vgt-table {
  width: 100% !important;
}
.vgt_table th {
  display: block !important;
  text-align: center !important;
}
.some-img {
  width: 100%;
  object-fit: cover;
}
.vgt-table-profile-tiktok .vgt-table tr {
  padding: 220px !important;
}
.vgt-table-profile-tiktok {
  overflow-x: scroll;
}
</style>
